import DefaultInfo from "./_default";
import {exerciseWithTime} from "./common/exercise";


class RevealInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
    ],
    modules: []
  };
}

export default RevealInfo;