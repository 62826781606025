import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {AdminPaths} from "../Admin/Admin";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";

import ReactRouteComponent from "mixins/ReactRouteComponentMixin";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Stats from "actions/stats";
import Project from "actions/project";
import Session from "actions/session";
import Exercise from "actions/exercise";
import ContentWithSidebar, {Content, ContentToolbar, Sidebar} from "components/layout/ContentWithSidebar";
import ButtonLink from "../ui/ButtonLink";
import {Card, Colors, H2, H3, H4, H5, HTMLTable, UL, OL} from "@blueprintjs/core";
import {
  PieChart,
  Pie,
  Tooltip as RechartTooltip,
  Cell,
  ResponsiveContainer,
  BarChart, Bar,
  XAxis, YAxis, LabelList
} from "recharts";

import "./Report.scss";

function msToS(value) {
  return (value / 1000).toFixed(1);
}

/**
 * @mixes ReactRouteComponent
 */
class Report extends ReactRouteComponent(Component) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
  };

  projectId
  sprintId
  exerciseId

  constructor(props) {
    super(props);

    this.projectId = this.getURLParameter('projectId');
    this.sprintId = this.getURLParameter('sprintId');
    this.exerciseId = this.getURLParameter('exerciseId');

    this.state = {
      dataInitialised: false,
      dataChanged: false,
      dataSaving: false,

      exercise: {},
      chosenAnswers: [],
    }
  }

  componentDidMount() {
    this.setProjectSprintExerciseId(
      this.projectId,
      this.sprintId,
      this.exerciseId);
    this.loadReport();
  }

  render() {
    const {exercise, rawData, processedData} = this.props;

    const hasTimeData = exercise && exercise.name !== "Kolejka" && exercise.name !== "Strzelanka"

    const reportAvailable = rawData && processedData && exercise && exercise.name !== undefined && exercise.id === this.exerciseId &&
      (Object.entries(processedData).length > 0) && (Object.entries(rawData).length > 0);
    return <ContentWithSidebar className="Report">
      <ContentToolbar>
        <ButtonLink minimal
          to={AdminPaths.openExercise(exercise.id)}
          icon={IconNames.CIRCLE_ARROW_LEFT}
          text="Wróć do ćwiczenia"
          disabled={exercise.id === undefined}
        />
      </ContentToolbar>
      <Content>
        {reportAvailable && <>
          <H2>Raport dla ćwiczenia: {exercise.name}</H2>
          <H3>Informacje ogólne</H3>
          <section>
            <H4>Poprawność wyborów</H4>
            <ResponsiveContainer height={300}>
              <PieChart>
                <Pie
                  isAnimationActive={false}
                  startAngle={90}
                  endAngle={450}
                  nameKey="name"
                  dataKey="value"
                  data={[
                    {
                      name: "Poprawne",
                      value: rawData['correct']['reaction_times'].length,
                    },
                    {
                      name: "Niepoprawne",
                      value: rawData['incorrect']['reaction_times'].length,
                    },
                  ]}
                  label={({index, percent}) => index === 0 ? `Poprawne (${(percent*100).toFixed(1)}%)` : `Niepoprawne (${(percent*100).toFixed(1)}%)`}
                >
                  <Cell fill={Colors.GREEN3} />
                  <Cell fill={Colors.RED3} />
                </Pie>
                <RechartTooltip />
              </PieChart>
            </ResponsiveContainer>
            <HTMLTable condensed striped>
              <thead>
              <tr>
                <th/>
                <th>Odpowiedzi</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Poprawne</td>
                <td>{rawData['correct']['reaction_times'].length}</td>
              </tr>
              <tr>
                <td>Niepoprawne</td>
                <td>{rawData['incorrect']['reaction_times'].length}</td>
              </tr>
              </tbody>
            </HTMLTable>
          </section>
          { hasTimeData &&
          <section>
            <H4>Średnia szybkość odpowiedzi</H4>
            <ResponsiveContainer height={300}>
              <BarChart
                data={[
                  {
                    name: "Poprawne",
                    value: processedData['correct']['average_reaction_time'],
                  },
                  {
                    name: "Niepoprawne",
                    value: processedData['incorrect']['average_reaction_time'],
                  },
                  {
                    name: "Wszystkie",
                    value: processedData['all']['average_reaction_time'],
                  },
                ]}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis
                  unit=" s"
                  tickFormatter={msToS}
                />
                <RechartTooltip />
                <Bar
                  dataKey="value"

                  unit="ms"
                >
                  <Cell fill={Colors.GREEN3} />
                  <Cell fill={Colors.RED3} />
                  <Cell  fill={Colors.BLUE3} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <HTMLTable condensed striped>
              <thead>
              <tr>
                <th/>
                <th>Szybkość</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Poprawne</td>
                <td>{msToS(processedData['correct']['average_reaction_time'])} s.</td>
              </tr>
              <tr>
                <td>Niepoprawne</td>
                <td>{msToS(processedData['incorrect']['average_reaction_time'])} s.</td>
              </tr>
              <tr>
                <td>Wszystkie</td>
                <td>{msToS(processedData['all']['average_reaction_time'])} s.</td>
              </tr>
              </tbody>
            </HTMLTable>
          </section>
          }
          <section>
            <Card>
              <H4>Dodatkowe informacje</H4>
              <UL>
                {processedData['all']['most_correct']['id'] &&
                <li>Pytaniem, na które najczęściej odpowiadano poprawnie, było
                  pytanie <strong>{exercise.questionsById[processedData['all']['most_correct']['id']]['content']}</strong> ({processedData['all']['most_correct']['count']}/{processedData['all']['most_correct']['total_count']} odpowiedzi
                  poprawnych)</li>
                }
                {processedData['all']['most_incorrect']['id'] &&
                <li>Pytaniem, z którym było najwięcej problemów, było
                  pytanie <strong>{exercise.questionsById[processedData['all']['most_incorrect']['id']]['content']}</strong> ({processedData['all']['most_incorrect']['count']}/{processedData['all']['most_incorrect']['total_count']} odpowiedzi
                  niepoprawnych)</li>
                }
                { hasTimeData && processedData['all']['fastest_question']['id'] &&
                <li>Najmniej zastanowienia wymagało
                  pytanie <strong>{exercise.questionsById[processedData['all']['fastest_question']['id']]['content']}</strong> (średni
                  czas odpowiedzi: {msToS(processedData['all']['fastest_question']['reaction_time'])} s.)</li>
                }
                { hasTimeData && processedData['all']['slowest_question']['id'] &&
                <li>Natomiast najbardziej zmuszało do zastanowienia
                  pytanie <strong>{exercise.questionsById[processedData['all']['slowest_question']['id']]['content']}</strong> (średni
                  czas odpowiedzi: {msToS(processedData['all']['slowest_question']['reaction_time'])} s.)</li>
                }
              </UL>
            </Card>
          </section>
          <H3>Szczegółowe informacje</H3>
          <section>
            <H4>Podział odpowiedzi</H4>
            <ResponsiveContainer height={25 + exercise.questions.length * 50}>
              <BarChart
                data={
                  exercise.questions.map((question, index) => {
                    const correct = rawData['correct']['reaction_times_by_question'][question['id']];
                    const incorrect = rawData['incorrect']['reaction_times_by_question'][question['id']];

                    return {
                      index,
                      correct: correct ? correct.length : 0,
                      incorrect: incorrect ? incorrect.length : 0,
                    }
                  })
                }
                margin={{
                  top: 20, right: 30, left: 20, bottom: 5,
                }}
                layout="vertical"
                dataKey="questionId"
              >
                <XAxis type="number" />
                <YAxis
                  dataKey="index"
                  tickFormatter={index => `Pytanie ${index + 1}`}

                  type="category"
                  interval={0}
                />
                <RechartTooltip
                  labelFormatter={index => exercise.questions[index]['content']}
                />
                <Bar layout="vertical" name="Poprawne" dataKey="correct" stackId="a" fill={Colors.GREEN3} />
                <Bar layout="vertical" name="Niepoprawne" dataKey="incorrect" stackId="a" fill={Colors.RED3} />
              </BarChart>
            </ResponsiveContainer>
          </section>
          { hasTimeData && <section>
            <H4>Szybkość reakcji</H4>
            <ResponsiveContainer height={25 + exercise.questions.length * 50}>
              <BarChart
                data={
                  exercise.questions.map((question, index) => {
                    const reactionTime = processedData['all']['average_reaction_time_by_question'][question['id']];

                    return {
                      index,
                      reactionTime: reactionTime ? reactionTime : 0,
                    }
                  })
                }
                margin={{
                  top: 20, right: 30, left: 20, bottom: 5,
                }}
                layout="vertical"
              >
                <XAxis type="number"
                  unit=" s"
                  tickFormatter={msToS}
                />
                <YAxis
                  dataKey="index"
                  tickFormatter={index => `Pyt. ${index + 1}`}

                  type="category"
                  interval={0}
                />
                <RechartTooltip
                  labelFormatter={index => exercise.questions[index]['content']}
                />
                <Bar
                  name="Średni czas reakcji"
                  dataKey="reactionTime"
                  unit="ms"

                  fill={Colors.ORANGE3}
                />
              </BarChart>
            </ResponsiveContainer>
          </section>}
          {exercise.questions.map((question, index) => {
            const answersData = question.answers.map((answer, index) => {
              const chosenCountCorrect = rawData['correct']['reaction_times_by_answer'][answer['id']];
              const chosenCountIncorrect = rawData['incorrect']['reaction_times_by_answer'][answer['id']];
              const reactionTime = processedData['all']['average_reaction_time_by_answer'][answer['id']];

              return {
                index,
                countCorrect: chosenCountCorrect ? chosenCountCorrect.length : 0,
                countIncorrect: chosenCountIncorrect ? chosenCountIncorrect.length : 0,
                reactionTime: reactionTime ? reactionTime : 0,
              }
            });

            return <section key={question['id']}>
              <Card className="question">
                <H4>Pyt. {index+1}. {question['content']}</H4>
                <OL className="answers">
                {question.answers.map((answer) => (
                  <li key={answer['id']} className={classNames("answer", {"correct": answer['correct']})}>{answer['content']}</li>
                ))}
                </OL>
                <H5>Liczba odpowiedzi</H5>
                <ResponsiveContainer height={25 + question.answers.length * 30}>
                  <BarChart
                    data={answersData}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                    layout="vertical"
                  >
                    <XAxis type="number" />
                    <YAxis
                      dataKey="index"
                      tickFormatter={index => `Odp. ${index + 1}`}

                      type="category"
                      interval={0}
                    />
                    <RechartTooltip
                      labelFormatter={index => question.answers[index]['content']}
                    />
                    <Bar
                      name="Liczba niepoprawnych odpowiedzi"
                      dataKey="countCorrect"
                      label={{ position: 'inside', fill: '#000' }}
                      stackId="count"

                      fill={Colors.GREEN3}
                    >
                      <LabelList dataKey="countCorrect" />
                    </Bar>
                    <Bar
                      name="Liczba poprawnych odpowiedzi"
                      dataKey="countIncorrect"
                      label={{ position: 'inside', fill: '#000' }}
                      stackId="count"

                      fill={Colors.RED3}
                    >
                      <LabelList
                        dataKey="countIncorrect"
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                { hasTimeData &&<H5>Szybkość odpowiedzi</H5>}
                { hasTimeData &&<ResponsiveContainer  height={25 + question.answers.length * 30}>
                  <BarChart
                    data={answersData}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                    layout="vertical"
                  >
                    <XAxis type="number"
                      unit=" s"
                      tickFormatter={msToS}
                    />
                    <YAxis
                      dataKey="index"
                      tickFormatter={index => `Odp. ${index + 1}`}

                      type="category"
                      interval={0}
                    />
                    <RechartTooltip
                      labelFormatter={index => question.answers[index]['content']}
                    />
                    <Bar
                      name="Szybkość odpowiedzi"
                      dataKey="reactionTime"
                      label={{ position: 'inside', fill: '#000' }}

                      fill={Colors.ORANGE3}
                    >
                      <LabelList dataKey="reactionTime" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>}
              </Card>
            </section>
          })}
        </>}
      </Content>
      <Sidebar />
    </ContentWithSidebar>
  }

  setProjectSprintExerciseId = (projectId, sprintId, exerciseId) => {
    this.props.setProjectSprintExerciseId(projectId, sprintId, exerciseId);
  };

  loadReport = () => {
    this.props.loadReport();
  };
}

const mapStateToProps = state => {
  const {exercise, session, project, stats} = state;

  return {
    exercise: {
      id: exercise.id,
      name: exercise.data ? exercise.data.name : undefined,
      questions: exercise.questions,
      questionsById: exercise.questionsById
    },
    sessionId: session.currentSessionId,
    instanceId: project.currentProjectId,

    rawData: stats.rawData,
    processedData: stats.processedData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadReport: () => {
      dispatch(Stats.loadReport());
    },
    setProjectSprintExerciseId: (projectId, sprintId, exerciseId) => {
      dispatch(Project.projectSelected(projectId));
      dispatch(Session.selectSessionById(sprintId));
      dispatch(Exercise.selectExerciseById(exerciseId));
      dispatch(Exercise.loadExercise(exerciseId));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Report));