import DefaultInfo from "./_default";
import {exerciseWithPointsPerAnswer, exerciseWithTime} from "./common/exercise"
import {answerWithFeedback} from "./common/answer"


class CleaningInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      ...exerciseWithPointsPerAnswer,
    ],
  };

  static answer = {
    ...DefaultInfo.answer,
    parameters: [
      ...DefaultInfo.answer.parameters,
      ...answerWithFeedback,
    ]
  };
}

export default CleaningInfo;