import FIELD_TYPES from "../../fieldTypes";

export const exerciseWithTime = [
  {
    id: "timePerQuestionSeconds",
    type: FIELD_TYPES.number,
    description: "Czas na odpowiedź [s]",
    placeholder: "liczba sekund",
    default: 60,
    inline: true,
    small: false,
  }
];

export const exerciseWithLearning = [
  {
    id: "isLearning",
    type: FIELD_TYPES.bool,
    description: "Ćwiczenie uczące?",
    default: false,

    inline: true,
    small: false,
  }
];

export const exerciseWithFeedback = [
  {
    id: "feedbackDisplayTimeS",
    type: FIELD_TYPES.number,
    description: "Czas pokazywania feedbacku [s]",
    placeholder: "liczba sekund",
    default: 5,
    inline: true,
    small: false,
  }
];

export const exerciseWithPointsPerAnswer = [
  {
    id: "pointsPerAnswer",
    type: FIELD_TYPES.number,
    description: "Ile punktów za prawidłową odpowiedź?",
    placeholder: "liczba punktów",
    default: 2,
    inline: true,
    small: false,
  }
];