import RiseFallInfo from "./info/rise_fall";
import DefaultInfo from "./info/_default";
import ElevatorInfo from "./info/elevator";
import MatchInfo from "./info/MatchInfo";
import MemoryInfo from "./info/memory";
import MessageInfo from "./info/message";
import OpenQuestionInfo from "./info/open_question";
import TilesInfo from "./info/TilesInfo";
import DoorsInfo from "./info/doors";
import TrainInfo from "./info/train";
import ParachutesInfo from "./info/ParachutesInfo";
import RevealInfo from "./info/RevealInfo";
import ShootingInfo from "./info/ShootingInfo";
import CauldronInfo from "./info/CauldronInfo";
import CleaningInfo from "./info/CleaningInfo"

const INFOS = {
  'rise_fall': RiseFallInfo,
  'elevator': ElevatorInfo,
  'match': MatchInfo,
  'memory': MemoryInfo,
  'message': MessageInfo,
  'open-question': OpenQuestionInfo,
  'tiles': TilesInfo,
  'doors': DoorsInfo,
  'train': TrainInfo,
  'parachutes': ParachutesInfo,
  'reveal': RevealInfo,
  'shooting': ShootingInfo,
  'cauldron': CauldronInfo,
  'cleaning': CleaningInfo,
};

export default class ExerciseInfoFactory {
  /**
   * @param exerciseSlug
   * @returns DefaultInfo
   */
  static getInfo(exerciseSlug) {
    const info = INFOS[exerciseSlug];

    return info ? info : DefaultInfo;
  }

  static getExerciseInfo(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).exercise;
  }

  static getQuestionInfo(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).question;
  }

  static getAnswerInfo(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).answer;
  }

  static canAddQuestion(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).canAddQuestion();
  }

  static canAddAnswer(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).canAddAnswer();
  }

  static canMoveAnswer(exerciseSlug) {
    return ExerciseInfoFactory.getInfo(exerciseSlug).canMoveAnswer();
  }
}